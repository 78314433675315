import { BaseElement, html, css, unsafeHTML } from 'Components';
import localStyles from './styles.js';
import { Session } from 'Utils';
import dayjs from 'dayjs';
import App from 'App';

class SessionInfos extends BaseElement {
  static get styles() {
    return [
      localStyles,
      css`

        sl-tree {
          --indent-guide-width: 1px;
          padding:0px;
          margin:0px;
        }

        sl-tree-item::part(item) {
          margin-left:-20px;
        }

        sl-tree-item::part(expand-button) {
          height:7px;
        }

        sl-tree-item::part(label) {
          font-size:14px;
          font-family:Calibri;
        }

        pre {
          font-size:0.8em;
          font-family:monospace;
          padding-left:2px;
          line-height:13px;
        }

        strong {
          min-width:100px;
        }

        .comment {
          font-size:1em;
        }
      `
    ];
  }

  static get properties() {
    return {
      sessionData: { type: Object }
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title: 'Current session',
            next_update:'Next update',
          }
        },
        french:{
          translation: {
            title: 'Session en cours',
            next_update:'Prochaine mise à jour',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.sessionData = null;
    this.handleSessionRefreshed = this.handleSessionRefreshed.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('session-refreshed', this.handleSessionRefreshed);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('session-refreshed', this.handleSessionRefreshed);
  }

  async handleSessionRefreshed() {
    this.sessionData = await Session.get();
    this.requestUpdate();
  }

  renderTree(data) {
    // Fonction pour générer récursivement la structure d'arbre
    const max = 50;

    if (typeof data === 'object' && data !== null) {
      return Object.entries(data).map(([key, value]) => {

        let displayValue = value;

        if (key === 'ith') return;
        
        if (key === 'groups') displayValue = `<pre>${JSON.stringify(value, null, 4)}</pre>`;
        if (key === 'exp' || key === 'iat' || key === 'auth_time') displayValue = new Date(value * 1000).toLocaleString();
        if (key === 'created') displayValue = new Date(value).toLocaleString();
        //if (key === 'roles') displayValue = value.join(', ');
        //if (key === 'required_actions')  displayValue = value.length ? value.join(', ') : 'none';

        if (typeof value === 'string' && value.length > max) {
          displayValue = `${value.substring(0, 50)}...`;
        }

        if (displayValue === 'boss') displayValue = '<font color="red">boss</font>';
        if (displayValue?.startsWith && displayValue.startsWith('http')) displayValue = `<a href="${value}" target="_blank">${displayValue}</a>`;

        if (typeof value === 'object' && Array.isArray(value)) {
          value = value.join(',');
        }

        if (typeof value === 'object' && Array.isArray(value)) {
          return html`
            <sl-tree-item expanded>
              <strong>${key}</strong>
              ${this.renderTree(value)}
            </sl-tree-item>
          `;
        } else if (typeof value === 'object') {
          return html`
            <sl-tree-item expanded>
              <strong>${key}</strong>
              ${this.renderTree(value)}
            </sl-tree-item>
          `;
        } else {
          return unsafeHTML(`
            <sl-tree-item>
              <strong>${key}</strong>
              ${displayValue}
            </sl-tree-item>
          `);
        }
      });
    } else {
      return html``;
    }
  }

  async firstUpdated() {
    super.firstUpdated();
    this.sessionData = await Session.get();
    this.requestUpdate();
  }

  formatTimestamp(timestamp) {
    if (App.config.language === 'french') {
      return dayjs(timestamp).locale('fr').format('DD/MM/YYYY HH:mm:ss');
    } else {
      return dayjs(timestamp).format('MM/DD/YYYY HH:mm:ss');
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  render() {
    if (!this.sessionData) return;
    return html`
      <section-header size="medium">${this._tl('title')}</section-header>
      <br/>
      <form>
        <div class="comment">${this._tl('next_update')} : ${this.formatTimestamp((this.sessionData.exp * 1000) + 2000)}</div><br/>
        <sl-tree>${this.renderTree(this.sessionData)}</sl-tree>
      </form>
      <br/><br/>
    `
  }

}

customElements.define('form-user-account-session-infos', SessionInfos);